.phone-input-container {
  position: relative;
}

.react-tel-input {
  display: flex;
  position: relative;
  font-family: inherit;
}

.react-tel-input .special-label {
  position: absolute;
  top: -4px;
  left: 22px;
  padding: 0 0.25rem;
  transition: 0.3s;
  color: var(--DARK_4);

  font-size: 10px !important;
  font-weight: 500;

  background-color: var(--CHECKOUT_BACKGROUND_COLOR);

  z-index: 10;
}

.react-tel-input .form-control {
  /* Remove box-shadow in safari */
  -webkit-appearance: none;
  height: -webkit-fit-content;
  width: 100%;
  padding: 17px 24px;
  z-index: 1;
  flex: 1;
  box-shadow: none;

  text-indent: 30px;
  font-size: 14px;
  font-weight: 500;

  border: 1px solid var(--DARK_9);
  border-radius: 8px;
  outline: none;
  background: none;
}

.react-tel-input .form-control:focus {
  border-color: var(--DARK_1);
  box-shadow: none;
}

.react-tel-input .form-control:active {
  border: 1px solid var(--DARK_1) !important;
}

.form-control::placeholder {
  color: var(--DARK_4);
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
}

.react-tel-input .form-control-error {
  border: 1px solid var(--ERROR_1);
}

.react-tel-input .form-control-error:focus {
  border: 1px solid var(--ERROR_1);
}

.react-tel-input .flag-dropdown {
  width: 100%;
}

.react-tel-input .flag-dropdown.open {
  z-index: inherit;
}

.react-tel-input .selected-flag {
  z-index: 8;
}

.react-tel-input .country-list {
  width: 100%;
  z-index: 25;
  box-shadow: none;
  max-height: 130px;
  background: var(--CHECKOUT_BACKGROUND_COLOR);
  border: 1px solid var(--DARK_9);
  border-radius: 8px;
}

.react-tel-input .country-list .country {
  color: var(--DARK_1);
  user-select: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 44px;
}

.react-tel-input .country-list .country .flag {
  top: 0;
}

.react-tel-input .country-list .country:hover {
  background-color: var(--SUMMARY_BUTTON_BACKGROUND_COLOR);
}

.react-tel-input .country-list .country:hover .country-name {
  color: var(--DROPDOWN_HIGHLIGHT_TEXT_COLOR);
}

.react-tel-input .country-list .country:hover .dial-code {
  color: var(--DROPDOWN_HIGHLIGHT_SECONDARY_TEXT_COLOR);
}

.react-tel-input .country-list .country.highlight {
  background-color: var(--SUMMARY_BUTTON_BACKGROUND_COLOR);
}

.react-tel-input .country-list .country.highlight .country-name {
  color: var(--DROPDOWN_HIGHLIGHT_TEXT_COLOR);
}

.react-tel-input .country-list .country.highlight .dial-code {
  color: var(--DROPDOWN_HIGHLIGHT_SECONDARY_TEXT_COLOR);
}

.react-tel-input + .error-message {
  color: var(--ERROR_1);
  position: absolute;
  bottom: -16px;
  font-size: 12px;
  font-weight: 500;
}
